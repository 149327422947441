<template>
    <el-dialog :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :modal-append-to-body="false" :lock-scroll="false" :visible.sync="visible">
        <!--  <div class="mod-config"> -->
        <el-form v-loading="loading" :model="dataForm" :rules="dataRule" ref="dataForm" class="alt_form" label-width="120px">
            <el-form-item :label="$t('dataDic.typeName')" prop="typeName">
                <el-input v-model="dataForm.typeName" :placeholder="$t('dataDic.typeName')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('dataDic.enTypeName')" prop="enTypeName">
                <el-input v-model="dataForm.enTypeName" :placeholder="$t('dataDic.enTypeName')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('dataDic.dicType')" prop="type">
                <el-input v-model="dataForm.type" :placeholder="$t('dataDic.dicType')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('dataDic.value')" prop="value">
                <el-input v-model="dataForm.value" :placeholder="$t('dataDic.value')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('dataDic.tagValue')" prop="name">
                <el-input v-model="dataForm.name" :placeholder="$t('dataDic.tagValue')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('dataDic.enTagValue')" prop="enName">
                <el-input v-model="dataForm.enName" :placeholder="$t('dataDic.enTagValue')"></el-input>
            </el-form-item>

            <el-form-item :label="$t('dataDic.remark')" prop="description">
                <el-input v-model="dataForm.description" :placeholder="$t('dataDic.remark')"></el-input>
            </el-form-item>

            <el-form-item :label="$t('dataDic.number')" prop="seq">
                <el-input-number v-model="dataForm.seq" controls-position="right" :min="0" label="排序号"></el-input-number>
            </el-form-item>

            <el-form-item :label="$t('dataDic.status')" prop="enable">
                <el-switch v-model="dataForm.enable" active-color="#13ce66" inactive-color="#ff4949" :active-text="$t('enable')" :inactive-text="$t('disable')">
                </el-switch>
            </el-form-item>
        </el-form>
        <div class="mod-footer">
            <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
            <el-button :disabled="loading" type="primary" @click="dataFormSubmit()">{{ $t('confirm') }}</el-button>
        </div>
        <!--  </div> -->
    </el-dialog>
</template>

<script>
import { getById, saveOrUpdate } from '@/api/sys/dict'
export default {
    data() {
        return {
            loading: false,
            visible: false,
            dataForm: {
                id: 0,
                value: "",
                name: "",
                enName:"",
                type: "",
                description: "",
                typeName: "",
                enTypeName: "",
                seq: 0,
                deleteFlag: "",
                enable: true
            },
            dataRule: {
                value: [
                    { required: true, message: this.$t('validate.required'), trigger: "blur" }
                ],
                name: [
                    { required: true, message: this.$t('validate.required'), trigger: "blur" }
                ],
                enName: [
                    { required: true, message: this.$t('validate.required'), trigger: "blur" }
                ],
                type: [
                    { required: true, message: this.$t('validate.required'), trigger: "blur" }
                ],
                typeName: [
                    { required: true, message: this.$t('validate.required'), trigger: "blur" }
                ],
                enTypeName: [
                    { required: true, message: this.$t('validate.required'), trigger: "blur" }
                ],
                seq: [{ required: true, message: this.$t('validate.required'), trigger: "blur" }]
            }
        };
    },
    // activated () {
    //   let id= this.$route.query.id
    //   this.init(id)
    // },
    methods: {
        init(id, type, typeName, enTypeName) {
            this.dataForm.id = id || 0;
            this.loading = false;
            this.visible = true;
            this.$nextTick(() => {
                this.$refs["dataForm"].resetFields();
                if (this.dataForm.id) {

                    getById(this.dataForm.id).then(({ data }) => {
                        if (data && data.code === 0) {
                          let res = data.data
                          for(let item in this.dataForm){
                            if(res[item] || res[item] === 0){
                              this.dataForm[item] = res[item] + ''
                            }else{
                              this.dataForm[item] = ""
                            }
                          }
                          this.dataForm.enable = res.deleteFlag == 0;
                        }
                    });
                } else if (type || typeName || enTypeName) {
                    this.dataForm.type = type;
                    this.dataForm.typeName = typeName;
                    this.dataForm.enTypeName = enTypeName;
                }
            });
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    this.loading = true;

                    const data_ = JSON.parse(JSON.stringify(this.dataForm))
                    data_.id = data_.id || undefined,
                    data_.deleteFlag = data_.enable ? 0 : 1

                    saveOrUpdate(data_).then(({ data }) => {
                            if (data && data.code === 0) {
                                this.$message.success(this.$t('prompt.success'))
                                this.visible = false;
                                this.$emit("refreshDataList");
                            } else {
                                this.$message.error(data.msg);
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        }
    }
};
</script>
