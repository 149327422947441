<template>
  <div class="mod-config">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form :inline="true" :model="typeForm" @submit.native.prevent @keyup.enter.native="typeCurrentChangeHandle(1)">
          <table class="detailTable_search" style="width: 100%">
            <tr>
              <td class="titleTd width80px">{{ $t('dataDic.typeName') }}</td>
              <td class="valueTd">
                <el-input v-model="typeForm.type" :placeholder="$t('dataDic.typeName')" clearable></el-input>
              </td>
            </tr>
          </table>
        </el-form>

        <div class="wxts_msg_search">
          <div class="f_t">
            <el-button-group>
              <el-button size="mini" @click="typeCurrentChangeHandle(1)" type="success" :disabled="typeListLoading">{{ $t('query') }}</el-button>
            </el-button-group>
          </div>
        </div>

      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-table :data="typesList" border highlight-current-row @current-change="handleCurrentChange" v-loading="typeListLoading" style="width: 100%;">
          <el-table-column prop="typeName" header-align="center" align="center" :label="$t('dataDic.typeName')"></el-table-column>
          <el-table-column prop="enTypeName" header-align="center" align="center" :label="$t('dataDic.enTypeName')"></el-table-column>
          <el-table-column prop="type" header-align="center" align="center" :label="$t('dataDic.dicType')"></el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" width="100" :label="$t('handle')">
            <template slot-scope="scope">
              <el-button type="text" class="update-button" size="small" @click="updateType(scope.row)">{{ $t('update') }}</el-button>
              <el-button type="text" class="del-button" size="small" @click="deleteType(scope.row)">{{ $t('delete') }}</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination class="mod-footer"
          v-if="!typeListLoading"
          @size-change="typeSizeChangeHandle"
          @current-change="typeCurrentChangeHandle"
          :current-page="typePageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="typePageSize"
          :total="typeTotalSize"
          layout="total, slot, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-col>

      <el-col :span="12">
        <el-form :inline="true" :model="dataForm">
          <table class="detailTable_search" style="width: 100%">
            <tr>
              <td class="titleTd width80px">{{ $t('dataDic.tagValue') }}</td>
              <td class="valueTd">
                <el-input v-model="dataForm.name" :placeholder="$t('dataDic.tagValue')" clearable></el-input>
              </td>
            </tr>
          </table>
        </el-form>

        <div class="wxts_msg_search">
          <div class="f_t">
            <el-button-group>
              <el-button size="mini" @click="currentChangeHandle(1)" type="success" :disabled="dataListLoading">{{ $t('query') }}</el-button>
              <el-button size="mini" v-if="isAuth('sys:sysdict:save')" :disabled="dataListLoading" type="primary" @click="addOrUpdateHandle()">{{ $t('add') }}</el-button>
              <el-button size="mini" v-if="isAuth('sys:sysdict:delete')" type="danger" @click="deleteHandle()"
                :disabled="dataListSelections.length <= 0 || dataListLoading">{{ $t('deleteBatch') }}</el-button>
            </el-button-group>
          </div>
        </div>
        <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
          <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
          <el-table-column prop="value" header-align="center" align="center" width="80" :label="$t('dataDic.value')"></el-table-column>
          <el-table-column prop="name" header-align="center" align="center" :label="$t('dataDic.tagValue')"></el-table-column>
          <el-table-column prop="enName" header-align="center" align="center" :label="$t('dataDic.enTagValue')"></el-table-column>
          <el-table-column prop="seq" header-align="center" align="center" width="80" :label="$t('dataDic.number')"></el-table-column>
          <!-- <el-table-column prop="updateTime" header-align="center" align="center" :show-overflow-tooltip="true" :label="$t('dataDic.updateTime')"></el-table-column> -->
          <el-table-column prop="deleteFlag" width="90" header-align="center" align="center" :label="$t('dataDic.status')">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.deleteFlag === 0" size="small" type="success">{{ $t('dataDic.available') }}</el-tag>
              <el-tag v-else-if="scope.row.deleteFlag === 1" size="small" type="info">{{ $t('dataDic.unavailable') }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" width="100" :label="$t('handle')">
            <template slot-scope="scope">
              <el-button type="text" class="update-button" size="small" @click="addOrUpdateHandle(scope.row.id)">{{ $t('update') }}</el-button>
              <el-button type="text" class="del-button" size="small" @click="deleteHandle(scope.row.id)">{{ $t('delete') }}</el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination class="mod-footer" v-if="!dataListLoading" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
          :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </el-col>
    </el-row>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>

    <TypeUpdate v-if="typeUpdateVisisble" ref="typeUpdate" @refreshDataList="typeCurrentChangeHandle(1)"></TypeUpdate>
  </div>
</template>

<script>
import AddOrUpdate from "./add-or-update";
import TypeUpdate from "./type-update";
import { getTypes, getList, deleteType, deleteDict } from '@/api/sys/dict'
export default {
  components: {
    AddOrUpdate,
    TypeUpdate
  },
  data () {
    return {
      currentType: {},

      typeForm: {
        type: ""
      },
      typesList: [], // 字典分类类型
      typePageIndex: 1,
      typePageSize: 10,
      typeTotalSize: 0,
      typeListLoading: false,

      typeUpdateVisisble: false,

      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,

      dataForm: {
        name: null
      },
      dataListSelections: [],
      addOrUpdateVisible: false
    };
  },
  activated () {
    this.getDataType();
  },
  methods: {
    handleCurrentChange (val) {
      this.currentType = val;
      this.currentChangeHandle(1);
    },
    // 获取分类类型
    getDataType () {
      this.typeListLoading = true
      getTypes({
        type: this.typeForm.type,
        page: this.typePageIndex,
        limit: this.typePageSize
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.typesList = data.data.list
          this.typeTotalSize = data.data.totalCount
          if (data.data.page != this.typePageIndex) {
            this.typePageIndex = data.data.page
          }
        } else {
          this.typesList = []
          this.typeTotalSize = []
          this.typePageIndex = 1
        }
      })
        .finally(() => {
          this.typeListLoading = false
        });
    },

    // 获取数据列表
    getDataList () {
      if (!this.currentType || !this.currentType.type) {
        this.dataList = [];
        this.totalCount = 0;

        return;
      }

      this.dataListLoading = true;

      getList({
        type: this.currentType ? this.currentType.type : undefined,
        name: this.dataForm.name,
        page: this.pageIndex,
        limit: this.pageSize
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
          if (data.data.page != this.pageIndex) {
            this.pageIndex = data.data.page
          }
        } else {
          this.dataList = []
          this.totalCount = 0
          this.pageIndex = 1
        }
      })
        .finally(() => {
          this.dataListLoading = false;
        });
    },
    // 每页数
    typeSizeChangeHandle (val) {
      this.typePageSize = val;
      this.typePageIndex = 1;
      this.getDataType();
    },
    // 当前页
    typeCurrentChangeHandle (val) {
      this.typePageIndex = val;
      this.getDataType();
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val;
    },
    updateType (row) {
      this.typeUpdateVisisble = true;
      this.$nextTick(() => {
        this.$refs.typeUpdate.init(row.type, row.typeName, row.enTypeName);
      });
    },
    deleteType (row) {
      let info = this.$t('dataDic.deleteDicTypeInfo')
      this.$confirm(`${info} 【${row.typeName}】?`, this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: "warning"
      }).then(() => {
        this.typeListLoading = true;

        deleteType(row.type).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: this.$t('prompt.success'),
              type: "success",
              duration: 500
            });
            this.typeCurrentChangeHandle(1);
          } else {
            this.$message.error(data.msg);
          }
        })
          .finally(() => {
            this.typeListLoading = false;
          });
      });
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        const type = this.currentType ? this.currentType.type : undefined;
        const typeName = this.currentType ? this.currentType.typeName : undefined;
        const enTypeName = this.currentType ? this.currentType.enTypeName : undefined;

        this.$refs.addOrUpdate.init(id, type, typeName, enTypeName);
      });
    },
    // 删除
    deleteHandle (id) {
      var ids = id ? [id] :
        this.dataListSelections.map(item => {
          return item.dictId;
        });
      this.$confirm(`${this.$t('dataDic.deleteTag')}?`, this.$t('dataDic.tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: "warning"
      }).then(() => {
        this.dataListLoading = true;

        deleteDict(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: this.$t('prompt.success'),
              type: "success",
              duration: 500,
              onClose: () => {
                this.getDataList();
              }
            });
          } else {
            this.$message.error(data.msg);
          }
        })
          .finally(() => {
            this.dataListLoading = false;
          });
      });
    }
  }
};
</script>
