<template>
  <el-dialog :title="!dataForm.type ? $t('add') : $t('update')" :close-on-click-modal="false" :modal-append-to-body="false" :lock-scroll="false"
    :visible.sync="visible">
    <!--  <div class="mod-config"> -->
    <el-form v-loading="loading" :model="dataForm" :rules="dataRule" ref="dataForm" class="alt_form" label-width="90px">
      <el-form-item :label="$t('dataDic.dicType')" prop="type">
        <el-input v-model="dataForm.type" :placeholder="$t('dataDic.dicType')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dataDic.typeName')" prop="typeName">
        <el-input v-model="dataForm.typeName" :placeholder="$t('dataDic.typeName')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('dataDic.enTypeName')" prop="enTypeName">
        <el-input v-model="dataForm.enTypeName" :placeholder="$t('dataDic.enTypeName')"></el-input>
      </el-form-item>
    </el-form>
    <div class="mod-footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button :disabled="loading" type="primary" @click="dataFormSubmit()">{{ $t('confirm') }}</el-button>
    </div>
    <!--  </div> -->
  </el-dialog>
</template>

<script>
import { updateType } from '@/api/sys/dict'
export default {
  data () {
    return {
      loading: false,
      visible: false,
      dataForm: {
        otype: "",
        type: "",
        typeName: "",
        enTypeName: "",
      },
      dataRule: {
        type: [
          { required: true, message: this.$t('validate.required'), trigger: "blur" }
        ],
        typeName: [
          { required: true, message: this.$t('validate.required'), trigger: "blur" }
        ],
        enTypeName: [
          { required: true, message: this.$t('validate.required'), trigger: "blur" }
        ],
      }
    };
  },
  methods: {
    init (type, typeName, enTypeName) {
      this.dataForm.type = type;
      this.dataForm.otype = type;
      this.dataForm.typeName = typeName;
      this.dataForm.enTypeName = enTypeName;
      this.loading = false;
      this.visible = true;
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.loading = true;

          updateType({
            oldType: this.dataForm.otype,
            type: this.dataForm.type,
            typeName: this.dataForm.typeName,
            enTypeName: this.dataForm.enTypeName
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message.success(this.$t('prompt.success'))
              this.visible = false;
              this.$emit("refreshDataList");
            } else {
              this.$message.error(data.msg);
            }
          })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
